
import Button from "primevue/button";
import Menubar from "primevue/menubar";
import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import SyringeIcon from "@/components/icons/SyringeIcon.vue";
import config from "@/config";
import { Role } from "@/global";
import AccountModule from "@/store/modules/account";
import ConsentModule from "@/store/modules/consent";
import SidebarModule from "@/store/modules/sidebar";
import ViewModule from "@/store/modules/view";

@Component({
  components: {
    Menubar,
    Button,
    SyringeIcon,
  },
})
export default class PrimaryMenu extends Vue {
  private readonly accountState = getModule(AccountModule);
  private readonly consentState = getModule(ConsentModule);
  private readonly viewState = getModule(ViewModule);
  private readonly sidebarState = getModule(SidebarModule);

  created() {
    if (this.accountState.parsedIDToken) {
      this.fetchProxies();
    }
  }

  get parsedXUAToken() {
    return this.accountState.parsedXUAToken;
  }

  @Watch("parsedXUAToken")
  onLoggedInUserChange() {
    if (this.accountState.loggedInUserGLN) {
      this.consentState.checkDelegationRight(this.accountState.loggedInUserGLN);
    }
  }

  handleAccessClick() {
    if (this.accessDisabled) {
      this.$notify({
        text: this.$t("toasts.documentAccess.noAccessRights").toString(),
        type: "error",
        duration: -1,
      });
    } else {
      this.$router.push({ name: "documentAccess" });
    }
  }

  async fetchProxies() {
    const failureMessage = await this.accountState.fetchProxies({ autoRepresent: true });

    if (failureMessage) {
      this.$notify({
        text: this.$t(failureMessage).toString(),
        type: "error",
        duration: -1,
      });
    }
  }

  toggleSidebar() {
    this.sidebarState.setIsSidebarOpen(!this.sidebarState.isSidebarOpen);
  }

  async openVaccinationModule() {
    let body = {
      firstName:
        this.accountState.parsedIDToken?.given_name || this.accountState.parsedIDToken?.sub,
      lastName:
        this.accountState.parsedIDToken?.family_name || this.accountState.parsedIDToken?.sub,

      language: this.$i18n.locale.replace("-", "_"),
      role: this.accountState.role,
      purpose: this.accountState.hasEmergencyAccess ? "EMER" : "NORM",
    };

    if (
      this.accountState.role == Role.HealthcareProfessional ||
      this.accountState.role == Role.Assistant
    ) {
      body = Object.assign(body, {
        gln: this.accountState.loggedInUserGLN,
      });
    }

    if (this.accountState.role == Role.Assistant) {
      body = Object.assign(body, {
        principalId: this.accountState.practitionerGLN,
        principalName: this.accountState.proxyPerson?.name,
      });
    }

    if (this.accountState.patientLocalID) {
      const [authority, value] = this.accountState.patientLocalID.split("|");
      body = Object.assign(body, {
        patientId: {
          value,
          authority,
        },
      });
    }

    try {
      const response = await fetch(config.eprServices.vaccinationRequest, {
        method: "POST",
        headers: {
          authorization: await this.accountState._getAuthenticationHeader(true),
          "content-type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const url = await response.text();
      window.open(url, "_blank");
    } catch (e) {
      Vue.notify({
        text: this.$t("toasts.vaccination.unableToLoad").toString(),
        type: "error",
        duration: -1,
      });
    }
  }

  get showPatientSearch(): boolean {
    return this.accountState.isProfessionalUser && !this.accountState.isPatientSelected;
  }

  get showDocuments(): boolean {
    return this.accountState.isPatientUser || this.accountState.isPatientSelected;
  }

  get showAccess(): boolean {
    return this.accountState.isPatientUser || this.accountState.isPatientSelected;
  }

  get accessDisabled(): boolean {
    return !this.accountState.isPatientUser && !this.consentState.canDelegate;
  }

  get showVaccination(): boolean {
    return (
      config.feature.vaccination &&
      (this.accountState.isPatientUser || this.accountState.isPatientSelected)
    );
  }

  get isMobile() {
    return this.viewState.isSmallScreen;
  }

  // NOTE: temporary
  get isTooltipDisabled() {
    return true;
  }

  get containerClass(): string {
    return this.viewState.isSmallScreen ? "tabbar" : "sidebar";
  }
}
