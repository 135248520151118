
import Button from "primevue/button";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import BackButton from "@/components/BackButton.vue";
import AccountModule from "@/store/modules/account";
import SidebarModule from "@/store/modules/sidebar";

import PatientTile from "./PatientTile.vue";

@Component({ components: { Button, BackButton, PatientTile } })
export default class AppBar extends Vue {
  private readonly sidebarState: SidebarModule = getModule(SidebarModule);
  private readonly accountState = getModule(AccountModule);

  private isSmallScreen: boolean = false;
  private isScrolled: boolean = false;

  get scrollContainer() {
    return document.getElementsByTagName("body")[0];
  }

  created() {
    window.addEventListener("resize", this.handleResize);
    this.scrollContainer.addEventListener("scroll", this.handleScroll);
    this.isSmallScreen = window.innerWidth < 768;
  }

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.scrollContainer.removeEventListener("scroll", this.handleScroll);
  }

  handleResize(event: any) {
    this.isSmallScreen = event.target.innerWidth < 768;
  }

  handleScroll() {
    this.isScrolled = this.scrollContainer.scrollTop > 0;
  }

  setSidebarOpen(actualState: boolean) {
    this.sidebarState.setIsSidebarOpen(actualState);
  }

  get isNestedPage(): boolean {
    return this.$route.path.replace(/\/*$/g, "").lastIndexOf("/") > 0;
  }
}
