
import Button from "primevue/button";
import { Component, Ref, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LanguageSelect from "@/components/LanguageSelect.vue";
import ProxySelect from "@/components/navigation/ProxySelect.vue";
import config from "@/config";
import AccountModule from "@/store/modules/account";

import LogoutButton from "./LogoutButton.vue";

@Component({
  components: { Button, LanguageSelect, ProxySelect, LogoutButton },
})
export default class SecondaryMenu extends Vue {
  @Ref("firstButton") private readonly firstButton!: Vue;

  private accountState = getModule(AccountModule);

  navigate(pathName: string) {
    if (this.$route.name !== pathName) {
      this.$router.push({ name: pathName });
    }
    this.hide();
  }

  hide() {
    this.$emit("hide");
  }

  logout() {
    this.hide();
    window.dispatchEvent(new CustomEvent("phellow:event:logout"));
  }

  focusFirstItem() {
    this.$nextTick(() => {
      if (this.firstButton?.$el instanceof HTMLElement) {
        this.firstButton.$el.focus();
      }
    });
  }

  get helpLink(): string {
    return this.$t(`community.url.help${config.role}`).toString();
  }

  get isPatientUser(): boolean {
    return this.accountState.isPatientUser;
  }

  get userName(): string {
    return this.accountState.currentUser?.name || "";
  }
}
