import { render, staticRenderFns } from "./ProxySelect.vue?vue&type=template&id=630eb128&scoped=true"
import script from "./ProxySelect.vue?vue&type=script&lang=ts"
export * from "./ProxySelect.vue?vue&type=script&lang=ts"
import style0 from "./ProxySelect.vue?vue&type=style&index=0&id=630eb128&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630eb128",
  null
  
)

export default component.exports