import { R4 } from "@ahryman40k/ts-fhir-types";
import dayjs from "dayjs";

import config from "@/config";
import { IDocumentReferenceExtended } from "@/store/modules/document";
import { hexToBytes } from "@/utils";

export async function deleteDocument(entry: IDocumentReferenceExtended, authorization: string) {
  const documentReference: R4.IDocumentReference = {
    resourceType: "DocumentReference",
    id: crypto.randomUUID(),
    extension: [
      {
        url: "http://fhir.ch/ig/ch-epr-mhealth/StructureDefinition/ch-ext-deletionstatus",
        valueCoding: {
          system:
            "http://fhir.ch/ig/ch-epr-mhealth/CodeSystem/ch-ehealth-codesystem-deletionstatus",
          code: "deletionRequested",
        },
      },
    ],
    content: [],
  };

  const submissionSet = generateSubmissionSet(documentReference);

  const bundle: R4.IBundle = {
    resourceType: "Bundle",
    meta: {
      profile: ["https://api.phellowseven.com/fhir/StructureDefinition/IHE.MHD.Metadata.Update"],
    },
    type: R4.BundleTypeKind._transaction,
    entry: [],
  };

  bundle.entry?.push({
    fullUrl: "List/" + submissionSet.id,
    resource: submissionSet,
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: "List/" + submissionSet.id,
    },
  });

  (documentReference as R4.IDocumentReference).id = entry?.id;

  bundle.entry?.push({
    fullUrl: "DocumentReference/" + entry?.id,
    resource: documentReference as R4.IDocumentReference,
    request: {
      method: R4.Bundle_RequestMethodKind._put,
      url: "DocumentReference/" + entry?.id,
    },
  });

  const url = config.r4.documents.upload;

  const response = await window.fetch(encodeURI(url), {
    method: "POST",
    body: JSON.stringify(bundle),
    headers: {
      Authorization: authorization,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to delete document");
  }
}

export function generateSubmissionSet(documentReference: R4.IDocumentReference): R4.IList {
  const masterIdentifier: R4.IIdentifier = (() => {
    const suffix = new Uint8Array(hexToBytes(crypto.randomUUID().replaceAll("-", "")));

    return {
      value: "urn:oid:2.25." + suffix.join(""),
      system: "urn:ietf:rfc:3986",
    };
  })();

  const identifier: R4.IIdentifier[] = [
    masterIdentifier,
    {
      value: "urn:uuid:" + crypto.randomUUID(),
      system: "urn:ietf:rfc:3986",
    },
  ];

  const id = crypto.randomUUID();

  const status = R4.ListStatusKind._current;

  const type: R4.IExtension = {
    url: "http://profiles.ihe.net/ITI/MHD/StructureDefinition/ihe-designationType",
    valueCodeableConcept: {
      coding: [
        {
          code: "71388002",
          system: "2.16.840.1.113883.6.96",
          display: "Procedure (procedure)",
        },
      ],
    },
  };
  const date = dayjs().format();

  const source: R4.IReference = {
    reference: "#1",
  };

  const authorRole: R4.IPractitionerRole = {
    id: `1`,
    resourceType: "PractitionerRole",
    code: [
      {
        coding: [
          {
            code: "PAT",
            system: "2.16.756.5.30.1.127.3.10.6",
          },
        ],
      },
    ],
  };

  const contained: R4.IResourceList[] = [authorRole];

  const entry: R4.IList_Entry[] = [
    {
      item: {
        reference: documentReference.id,
      },
    },
  ];

  const list: R4.IList = {
    resourceType: "List",
    extension: [type],
    code: {
      coding: [
        {
          system: "http://profiles.ihe.net/ITI/MHD/CodeSystem/MHDlistTypes",
          code: "submissionset",
        },
      ],
    },
    identifier,
    id,
    status,
    date,
    source,
    contained,
    entry,
  };

  return list;
}

export async function updateDocumentStatus(
  entry: IDocumentReferenceExtended,
  status: R4.DocumentReferenceStatusKind,
  authorization: string
) {
  const documentReference: R4.IDocumentReference = {
    resourceType: "DocumentReference",
    id: crypto.randomUUID(),
    status,
    content: [],
  };

  const submissionSet = generateSubmissionSet(documentReference);

  const bundle: R4.IBundle = {
    resourceType: "Bundle",
    meta: {
      profile: ["https://api.phellowseven.com/fhir/StructureDefinition/IHE.MHD.Metadata.Update"],
    },
    type: R4.BundleTypeKind._transaction,
    entry: [],
  };

  bundle.entry?.push({
    fullUrl: "List/" + submissionSet.id,
    resource: submissionSet,
    request: {
      method: R4.Bundle_RequestMethodKind._post,
      url: "List/" + submissionSet.id,
    },
  });

  (documentReference as R4.IDocumentReference).id = entry?.id;

  bundle.entry?.push({
    fullUrl: "DocumentReference/" + entry?.id,
    resource: documentReference as R4.IDocumentReference,
    request: {
      method: R4.Bundle_RequestMethodKind._put,
      url: "DocumentReference/" + entry?.id,
    },
  });

  const url = config.r4.documents.upload;

  const response = await window.fetch(encodeURI(url), {
    method: "POST",
    body: JSON.stringify(bundle),
    headers: {
      Authorization: authorization,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to update document status");
  }
}
