var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"primary-menu",class:_vm.containerClass},[(_vm.showPatientSearch)?_c('router-link',{attrs:{"to":{ name: 'patients' },"tabindex":"-1"}},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
          value: _vm.$t('patients.search.title').toString(),
          disabled: _vm.isTooltipDisabled,
        }),expression:"{\n          value: $t('patients.search.title').toString(),\n          disabled: isTooltipDisabled,\n        }",modifiers:{"right":true}}],staticClass:"p-button-text p-button-secondary",attrs:{"label":_vm.$t('navigation.actions.patients'),"icon":"pi pi-search","data-cy":"patient-search-nav-item"}})],1):_vm._e(),(_vm.showDocuments)?_c('router-link',{attrs:{"to":{ name: 'documents' },"tabindex":"-1"}},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
          value: _vm.$t('navigation.actions.documents').toString(),
          disabled: _vm.isTooltipDisabled,
        }),expression:"{\n          value: $t('navigation.actions.documents').toString(),\n          disabled: isTooltipDisabled,\n        }",modifiers:{"right":true}}],staticClass:"p-button-text p-button-secondary",attrs:{"label":_vm.$t('navigation.actions.documents'),"icon":"pi pi-file","data-cy":"documents-nav-item"}})],1):_vm._e(),(_vm.showVaccination)?_c('a',[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
          value: _vm.$t('navigation.actions.vaccination').toString(),
          disabled: _vm.isTooltipDisabled,
        }),expression:"{\n          value: $t('navigation.actions.vaccination').toString(),\n          disabled: isTooltipDisabled,\n        }",modifiers:{"right":true}}],staticClass:"flex flex-row p-button-text p-button-secondary",attrs:{"data-cy":"vaccination-nav-item"},on:{"click":_vm.openVaccinationModule}},[_c('div',{staticClass:"p-button-icon p-button-icon-left vaccination-icon"},[_c('SyringeIcon',{staticClass:"w-[19px] h-[19px]"})],1),_c('span',{staticClass:"p-button-label vaccination-label"},[_vm._v(" "+_vm._s(_vm.$t("navigation.actions.vaccination"))+" ")])])],1):_vm._e(),(_vm.showAccess)?_c('router-link',{attrs:{"to":{ name: 'documentAccess' },"tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.handleAccessClick.apply(null, arguments)}}},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
          value: _vm.$t('navigation.actions.documentAccess').toString(),
          disabled: _vm.isTooltipDisabled,
        }),expression:"{\n          value: $t('navigation.actions.documentAccess').toString(),\n          disabled: isTooltipDisabled,\n        }",modifiers:{"right":true}}],staticClass:"p-button-text p-button-secondary",attrs:{"label":_vm.$t('navigation.actions.access'),"icon":"pi pi-key","data-cy":"access-rights-nav-item"}})],1):_vm._e(),(_vm.accountState.isPatientUser)?_c('router-link',{attrs:{"to":{ name: 'accessLogs' },"tabindex":"-1"}},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
          value: _vm.$t('navigation.actions.accessLogs').toString(),
          disabled: _vm.isTooltipDisabled,
        }),expression:"{\n          value: $t('navigation.actions.accessLogs').toString(),\n          disabled: isTooltipDisabled,\n        }",modifiers:{"right":true}}],staticClass:"p-button-text p-button-secondary",attrs:{"label":_vm.$t('navigation.actions.logs'),"icon":"pi pi-list","data-cy":"access-logs-nav-item"}})],1):_vm._e()],1),_c('div',{staticClass:"md:hidden h-[72px] w-full"})])
}
var staticRenderFns = []

export { render, staticRenderFns }