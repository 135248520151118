
import Avatar from "primevue/avatar";
import OverlayPanel from "primevue/overlaypanel";
import Ripple from "primevue/ripple";
import { Component, Ref, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import AppIcon from "@/components/AppIcon.vue";
import PrimaryMenu from "@/components/navigation/PrimaryMenu.vue";
import SecondaryMenu from "@/components/navigation/SecondaryMenu.vue";
import config from "@/config";
import AccountModule from "@/store/modules/account";
import { getInitials } from "@/utils";

@Component({
  components: {
    PrimaryMenu,
    AppIcon,
    Avatar,
    OverlayPanel,
    SecondaryMenu,
  },
  directives: {
    ripple: Ripple,
  },
})
export default class Sidebar extends Vue {
  @Ref("secondaryMenuOverlay") private readonly secondaryMenuOverlay!: any;
  @Ref("secondaryMenu") private readonly secondaryMenu!: any;

  private readonly accountState = getModule(AccountModule);

  get title(): string {
    return config.homeCommunity.name || "";
  }

  get isFullyLoggedIn(): boolean {
    return !!this.$route.name && !this.$route.name.startsWith("register");
  }

  get badgeLabel(): string | null {
    switch (this.accountState.role) {
      case "ASS":
        return this.$t("navigation.proxies.ASS").toString();
      case "REP":
        return this.$t("navigation.proxies.REP").toString();
      default:
        return null;
    }
  }

  focusSecondaryMenu() {
    this.$nextTick(() => {
      if (this.secondaryMenu) {
        this.secondaryMenu.focusFirstItem();
      }
    });
  }

  toggleSecondaryMenu(event: any) {
    this.secondaryMenuOverlay.toggle(event);
  }

  get avatarLabel() {
    return getInitials(this.accountState.currentUser?.name || "");
  }
}
