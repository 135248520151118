import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import Store from "../index";

@Module({
  dynamic: true,
  store: Store,
  name: "view",
  namespaced: true,
})
export default class ViewModule extends VuexModule {
  isFullscreen: boolean = false;
  isSmallScreen: boolean = window.innerWidth < 768;
  isExtraSmallScreen: boolean = window.innerWidth < 640;
  isLargeScreen: boolean = window.innerWidth < 1280;
  isExtraLargeScreen: boolean = window.innerWidth >= 1280;

  @Action
  initListeners() {
    window.addEventListener("resize", this.handleResize);
  }

  @Action
  removeListeners() {
    window.removeEventListener("resize", this.handleResize);
  }

  // NOTE: maybe merge the two store to avoid calling other states
  @Action
  private handleResize(event: any) {
    this.setSmallScreen(event.target.innerWidth < 768);
    this.setExtraSmallScreen(event.target.innerWidth < 640);
    this.setLargeScreen(event.target.innerWidth < 1280);
    this.setExtraLargeScreen(event.target.innerWidth >= 1280);
    this.context.commit("sidebar/setIsSidebarOpen", event.target.innerWidth >= 1024, {
      root: true,
    });
  }

  @Mutation
  setShowFullscreen(fullscreen: boolean) {
    this.isFullscreen = fullscreen;
  }

  @Mutation
  setExtraLargeScreen(isExtraLargeScreen: boolean) {
    this.isExtraLargeScreen = isExtraLargeScreen;
  }

  @Mutation
  setLargeScreen(isLargeScreen: boolean) {
    this.isLargeScreen = isLargeScreen;
  }

  @Mutation
  setSmallScreen(isSmallScreen: boolean) {
    this.isSmallScreen = isSmallScreen;
  }

  @Mutation
  setExtraSmallScreen(isExtraSmallScreen: boolean) {
    this.isExtraSmallScreen = isExtraSmallScreen;
  }
}
