
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import { UnauthorizedError } from "@/global";
import AccountModule from "@/store/modules/account";
import DocumentModule from "@/store/modules/document";

import PurePatientTile from "./PurePatientTile.vue";

@Component({
  components: {
    PurePatientTile,
  },
})
export default class PatientTile extends Vue {
  private accountState = getModule(AccountModule);
  private documentState = getModule(DocumentModule);

  async logOutPatient() {
    this.accountState.logOutPatient();
    if (this.$route.name != "patients") {
      this.$router.push({ name: "patients" });
    }
  }

  deactivateEmergencyAccess() {
    this.accountState.setHasEmergencyAccess(false);
    this.accountState.setEmergencyAccessRequested(false);
    this.accountState.clearXUAToken();
    this.documentState.clearDocuments();
    this.accountState.setNoAccessToThisEPD(false);
    this.accountState
      .getAuthenticationHeader()
      .then(() => {
        if (this.$route.name == "documents") {
          this.documentState
            .fetchDocuments()
            .then(() => {
              this.accountState.setNoAccessToThisEPD(false);
              this.$notify({
                text: this.$t("toasts.documentStore.documentsFetchSuccess").toString(),
                type: "success",
                data: { srOnly: true },
              });
            })
            .catch((error) => {
              if (error instanceof UnauthorizedError) {
                this.accountState.setNoAccessToThisEPD(true);
              } else {
                this.accountState.setNoAccessToThisEPD(false);
                this.$notify({
                  text: this.$t("toasts.documentStore.documentsFetchFail").toString(),
                  type: "error",
                  duration: -1,
                });
              }
            });
        } else {
          this.$router.push({ name: "documents", params: { _reload: "true" } });
        }
      })
      .catch(() => {
        this.accountState.clearSelectedPatient();
        this.accountState.clearPatientSPID();
        this.accountState.clearPatientLocalID();
        this.documentState.clearDocuments();
        this.$router.push({ name: "patients" });
      });
  }
}
