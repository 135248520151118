
import Sidebar from "primevue/sidebar";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import AppIcon from "@/components/AppIcon.vue";
import SecondaryMenu from "@/components/navigation/SecondaryMenu.vue";
import config from "@/config";
import AccountModule from "@/store/modules/account";
import SidebarModule from "@/store/modules/sidebar";
import ViewModule from "@/store/modules/view";

@Component({ components: { AppIcon, SecondaryMenu, Sidebar } })
export default class MenuDrawer extends Vue {
  private accountState = getModule(AccountModule);
  private sidebarState = getModule(SidebarModule);
  private viewState = getModule(ViewModule);

  get isVisible() {
    if (
      !this.viewState.isSmallScreen ||
      !this.accountState.parsedIDToken ||
      this.$route.path.startsWith("/login")
    ) {
      return false;
    }

    return this.sidebarState.isSidebarOpen;
  }

  set isVisible(value: boolean) {
    this.sidebarState.setIsSidebarOpen(value);
  }

  get title(): string {
    return config.homeCommunity.name || "";
  }
}
