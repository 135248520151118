import { R4 } from "@ahryman40k/ts-fhir-types";
import Vue from "vue";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";

import config from "@/config";
import { ExtendedConsent, IConsent } from "@/definitions/ppqm";
import i18n from "@/i18n";

import Store from "../index";
import AccountModule from "./account";
import HCProviderModule, { ConsentHCPSearch } from "./hcprovider";

const accountState = getModule(AccountModule);
const hcproviderState = getModule(HCProviderModule);

export enum PolicySetIdReferenceValue_AccessLevel {
  EXCLUSION_LIST = "urn:e-health-suisse:2015:policies:exclusion-list",
  NORMAL = "urn:e-health-suisse:2015:policies:access-level:normal",
  DELEGATION_AND_NORMAL = "urn:e-health-suisse:2015:policies:access-level:delegation-and-normal",
  RESTRICTED = "urn:e-health-suisse:2015:policies:access-level:restricted",
  DELEGATION_AND_RESTRICTED = "urn:e-health-suisse:2015:policies:access-level:delegation-and-restricted",
  FULL = "urn:e-health-suisse:2015:policies:access-level:full",
}

export enum PolicySetIdReferenceValue_EmergencyAccessLevel {
  NORMAL = "urn:e-health-suisse:2015:policies:access-level:normal",
  RESTRICTED = "urn:e-health-suisse:2015:policies:access-level:restricted",
}

export enum PolicySetIdReferenceValue_ProvideLevel {
  NORMAL = "urn:e-health-suisse:2015:policies:provide-level:normal",
  RESTRICTED = "urn:e-health-suisse:2015:policies:provide-level:restricted",
  SECRET = "urn:e-health-suisse:2015:policies:provide-level:secret",
}

const authHeaderFunction = async function (): Promise<string | null> {
  try {
    return accountState.getAuthenticationHeader();
  } catch (error) {
    Vue.notify({
      text: i18n.t("toasts.auth.tokenFetchFail").toString(),
      type: "error",
      duration: -1,
    });
    return null;
  }
};

@Module({
  dynamic: true,
  store: Store,
  name: "consent",
  namespaced: true,
})
export default class ConsentModule extends VuexModule {
  private consentResources: Map<string, IConsent> = new Map();
  private consentArray: IConsent[] = [];
  public isLoading: boolean = false;
  public fetched: boolean = false;
  public canDelegate: boolean = false;

  @Mutation
  clearConsent() {
    this.consentResources = new Map();
    this.consentArray = [];
    this.fetched = false;
    this.isLoading = false;
    this.canDelegate = false;
  }

  @Mutation
  setFetched(fetched: boolean) {
    this.fetched = fetched;
  }

  @Mutation
  setCanDelegate(canDelegate: boolean) {
    this.canDelegate = canDelegate;
  }

  @Mutation
  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  setConsentResources(consentResources: Map<string, IConsent>) {
    this.consentResources = consentResources;
    this.consentArray = Array.from(consentResources.values());
  }

  @Action
  async reloadConsent() {
    await this.fetchConsent(true);
  }

  @Action({ rawError: true })
  async fetchConsent(force: boolean = false) {
    if (this.fetched && !force) {
      return;
    }

    this.setLoading(true);

    const url = config.r4.consent;

    try {
      const authHeader = await authHeaderFunction();
      if (authHeader == null) {
        return {
          consentResources: new Map(),
          isLoading: false,
        };
      }

      const response = await window.fetch(encodeURI(url), {
        method: "GET",
        headers: {
          Authorization: authHeader,
        },
      });

      if (!response.ok) {
        this.setConsentResources(new Map());
        this.setLoading(false);
        throw new Error("Response not OK");
      }

      const bundle = (await response.json()) as R4.IBundle;

      if (bundle.entry) {
        const fetchedConsent = bundle.entry.map(
          (consent) => new ExtendedConsent(consent.resource as R4.IConsent)
        );

        const policies = fetchedConsent;

        const mappedPolicies: Map<string, IConsent> = new Map();

        policies.forEach((policy) => {
          mappedPolicies.set(policy.policySetID!, policy);
        });

        this.setConsentResources(mappedPolicies);
        this.setLoading(false);
      } else {
        this.setConsentResources(new Map());
        this.setLoading(false);
      }
    } finally {
      this.setLoading(false);
      this.setFetched(true);
    }
  }

  @Action
  async getConsentForHCP(hcpID: string): Promise<IConsent | null> {
    if (!this.consentArray) return null;

    return (
      this.consentArray.find(
        (consent) => consent.hcpID == hcpID || consent.hcpID == "urn:oid:" + hcpID
      ) || null
    );
  }

  get blacklist(): IConsent[] {
    return this.consentArray
      .filter(
        (consent) =>
          (consent.templateID == "301" || consent.templateID == "302") &&
          consent.accessLevel == PolicySetIdReferenceValue_AccessLevel.EXCLUSION_LIST
      )
      .map((policy) => {
        if (policy.hcpID) {
          policy.hcp = hcproviderState.consentHCPs.get(policy.hcpID.replace("urn:oid:", ""));
        }
        return policy;
      });
  }

  get restricted(): IConsent[] {
    return this.consentArray
      .filter(
        (consent) =>
          (consent.templateID == "301" || consent.templateID == "302") &&
          (consent.accessLevel == PolicySetIdReferenceValue_AccessLevel.RESTRICTED ||
            consent.accessLevel == PolicySetIdReferenceValue_AccessLevel.DELEGATION_AND_RESTRICTED)
      )
      .map((policy) => {
        if (policy.hcpID) {
          policy.hcp = hcproviderState.consentHCPs.get(policy.hcpID.replace("urn:oid:", ""));
        }
        return policy;
      });
  }

  get normal(): IConsent[] {
    return this.consentArray
      .filter(
        (consent) =>
          (consent.templateID == "301" || consent.templateID == "302") &&
          (consent.accessLevel == PolicySetIdReferenceValue_AccessLevel.NORMAL ||
            consent.accessLevel == PolicySetIdReferenceValue_AccessLevel.DELEGATION_AND_NORMAL)
      )
      .map((policy) => {
        if (policy.hcpID) {
          policy.hcp = hcproviderState.consentHCPs.get(policy.hcpID.replace("urn:oid:", ""));
        }
        return policy;
      });
  }

  @Mutation
  removeConsent(policySetID: string) {
    const isSuccess = this.consentResources.delete(policySetID);
    if (isSuccess) {
      this.consentArray = Array.from(this.consentResources.values());
    }
  }

  get emergencyAccessPolicy(): IConsent | null {
    return this.consentArray.find((consent) => consent.templateID == "202") || null;
  }

  get patientProvideLevel(): IConsent | null {
    return this.consentArray.find((consent) => consent.templateID == "203") || null;
  }

  get spid(): string | null {
    for (const consent of this.consentArray || []) {
      if (consent.resourceID) return consent.resourceID;
    }

    return null;
  }

  get hcpSearchParameters(): ConsentHCPSearch[] {
    const concatenated = this.normal.concat(this.restricted).concat(this.blacklist);

    return concatenated.map((policy) => {
      const isPractitioner = policy.isPractitioner;

      const identifier = encodeURI((isPractitioner ? "GLN|" : "OID|") + policy.hcpID);
      return {
        isPractitioner,
        identifier,
      };
    });
  }

  @Action({ rawError: true })
  async accessRightsForCurrentHCP(
    gln: string
  ): Promise<PolicySetIdReferenceValue_AccessLevel | null> {
    const consent = await this.getConsentForHCP(gln);
    return (consent?.accessLevel as PolicySetIdReferenceValue_AccessLevel) || null;
  }

  @Action({ rawError: true })
  async checkDelegationRight(gln?: string) {
    if (!gln) {
      this.setCanDelegate(false);
      return;
    }

    if (!this.fetched) {
      try {
        await this.fetchConsent();
      } catch (error) {
        this.setCanDelegate(false);
        return;
      }
    }

    const accessLevel = await this.accessRightsForCurrentHCP(gln);
    if (!accessLevel) {
      this.setCanDelegate(false);
      return;
    }

    switch (accessLevel) {
      case PolicySetIdReferenceValue_AccessLevel.DELEGATION_AND_NORMAL:
      case PolicySetIdReferenceValue_AccessLevel.DELEGATION_AND_RESTRICTED:
        this.setCanDelegate(true);
        break;
      default:
        this.setCanDelegate(false);
    }
  }
}
